import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';

import { environment } from 'src/environments/environment.local';
import { User } from '../models/user.model';
import { ApiCallService } from 'src/app/services/api-call.service';


@Injectable({ providedIn: 'root' })
export class AuthenticationService {
    user: User | null = null;

    constructor(private http: HttpClient) {
    }

    /**
     * Returns the current user
     */
    public currentUser(): User | null {
        if (!this.user) {
            this.user = JSON.parse(sessionStorage.getItem('currentUser')!);
        }
        if(!this.user){
            this.user = JSON.parse(localStorage.getItem('currentUser')!);
        }
        return this.user;
    }

    /**
     * Performs the login auth
     * @param email email of user
     * @param password password of user
     */
    login(email: string, password: string, remember: boolean = false): any {

        return this.http.post<any>(environment.appUrl + `/auth/login`, { email, password })
            .pipe(map(res => {
                // login successful if there's a jwt token in the response
                if(!res.success){
                    throw new Error(res.message);
                }
                if (res.body.user && res.body.user.token) {
                    res.body.user.avatar = 'assets/images/users/avatar.svg';
                    this.user = res.body.user;
                    // store user details and jwt in session
                    sessionStorage.setItem('currentUser', JSON.stringify(res.body.user));
                    console.log(remember);
                    if(remember){
                        localStorage.setItem('currentUser', JSON.stringify(res.body.user));
                    }
                }
                return res.body.user;
            }));
    }

    /**
     * Performs the signup auth
     * @param firstName  first name of user
     * @param lastName  last name of user
     * @param email email of user
     * @param password password of user
     */
    signup(firstName: string, lastName: string, email: string, password: string): any {
        return this.http.post<any>(environment.appUrl + `/auth/signup`, { firstName, lastName, email, password })
            .pipe(map(user => user));

    }



    /**
     * Logout the user
     */
    logout(): void {
        // remove user from session storage to log user out
        sessionStorage.removeItem('currentUser');
        localStorage.removeItem('currentUser');
        this.user = null;
    }
}

