/* eslint-disable guard-for-in */
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
// import { AlertController, LoadingController } from '@ionic/angular';
import { environment } from 'src/environments/environment.local';
import { UtilsService } from './utils.service';
import { AuthenticationService } from '../core/service/auth.service';

export interface ApiGetCallData {
    query?: Record<any, unknown>;
    skipLoading?: boolean;
    dest: string;
    skipRedirect?: boolean;
}

export interface ApiPostCallData {
    postType: PostType;
    dest: string;
    body: any;
    skipLoading?: boolean;
}

export enum PostType {
    formData, json
}

@Injectable({
    providedIn: 'root'
})

export class ApiCallService {

    constructor(private http: HttpClient, private router: Router, private loadingController: LoadingController,
        private utils: UtilsService, private auth: AuthenticationService) { }

    async get(data: ApiGetCallData | string) {
        let queryString = '';
        if (typeof data == 'string') {
            const settings = this.getHttpSettings(null);
            return this.http.get(environment.appUrl + data + '?' + queryString, settings).toPromise()
        }
        if (data.query != null) {
            queryString = this.encodeQueryData(data.query);
        }
        const settings = this.getHttpSettings(null);
        if (!data.skipLoading) {
            const loading = await this.loadingController.create({
                message: 'Please wait...',
                translucent: true,
                spinner: 'crescent',
                cssClass: 'custom-loader-class'
            });
            loading.present();
        }
        const ans = await this.http.get(environment.appUrl + data.dest + '?' + queryString, settings).toPromise()
            .then(async (res) => {
                return res;
            })
            .catch(async (err) => {
                if (err.status === 401) {
                    if (data.skipRedirect) {
                        return this.router.navigateByUrl('/auth/login', { replaceUrl: true });
                    } else {
                        return false;
                    }
                }
                if (err.status === 500) {
                    this.utils.presentToast('Internal Server Error', 'danger');
                }
                return err;
            })
            .finally(async () => {
                if (!data.skipLoading) {
                    // setTimeout(async () => {
                    await this.loadingController.dismiss();
                    // },1000)
                }
            });
        return ans;
    }

    async post(data: ApiPostCallData) {
        const settings = this.getHttpSettings(data.postType);
        let url = environment.appUrl;
        if (data.body.country) {
            url = environment.appUrl.replace('app', data.body.country);
        }
        if (!data.skipLoading) {
            const loading = await this.loadingController.create({
                message: 'Please wait...',
                translucent: true,
                spinner: 'crescent',
                cssClass: 'custom-loader-class'
            });
            loading.present();
        }
        
            const response = await this.http.post(url + data.dest, data.body, settings).toPromise()
            .then(async(res) => {return res})
            .catch(async (e) =>  {
                console.log(e);
                this.utils.presentToast(e, 'danger');
                return { success: false };
        })  .finally(async () => {
            if (!data.skipLoading) {
                // setTimeout(async () => {
                await this.loadingController.dismiss();
                // },1000)
            }
        });
        return response;
    }
    async put(data: ApiPostCallData) {
        const settings = this.getHttpSettings(data.postType);
        let url = environment.appUrl;
        // url.replace('app.gib.ai', window.location.hostname);
        if (data.body.country) {
            url = environment.appUrl.replace('app', data.body.country);
        }
        if (!data.skipLoading) {
            const loading = await this.loadingController.create({
                message: 'Please wait...',
                translucent: true,
                spinner: 'crescent',
                cssClass: 'custom-loader-class'
            });
            loading.present();
        }

            const response = await this.http.put(url + data.dest, data.body, settings).toPromise()
            .then(async (res)=>{return res})
         .catch (async (e) => {
            this.utils.presentToast(e, 'danger');
            return { success: false };
        }).finally(async () => {
            if (!data.skipLoading) {
                // setTimeout(async () => {
                await this.loadingController.dismiss();
                // },1000)
            }
        } );
        return response;
    }

    async patch(data: ApiPostCallData) {
        const settings = this.getHttpSettings(data.postType);
        const ans = await this.http.patch(environment.appUrl + data.dest, data.body, settings).toPromise()
            .then((res) => {
                return res;
            })
            .catch(async (err) => {
                if (err.status === 401) {
                    return this.router.navigateByUrl('/welcome');
                }
                if (err.status === 500) {
                    return err;
                }
            });
        return ans;
    }

    async delete(data: ApiGetCallData) {
        let queryString = '';
        if (data.query != null) {
            queryString = this.encodeQueryData(data.query);

        }
        const settings = this.getHttpSettings(null);
        const ans = await this.http.delete(environment.appUrl + data.dest + '?' + queryString, settings).toPromise()
            .then((res) => {
                return res;
            })
            .catch((err) => {
                if (err.status === 401) {
                    return this.router.navigateByUrl('/welcome', { replaceUrl: true });
                }
                return err;
            });
        return ans;
    }

    getHttpSettings(postType: any) {
        const settings = { withCredentials: true, headers: new HttpHeaders() };
        // const headers = new Headers();
        settings.headers.append('Access-Control-Allow-Origin', environment.appUrl);
        settings.headers.append('Access-Control-Allow-Credentials', 'true');
        if (postType === 'formData') {
            settings.headers.append('Content-Type', 'application/form-data');
        }
        let token = this.auth.currentUser()?.token;
        if (token) {
            settings.headers.append('Authorization', 'Bearer ' + token);
        }
        return settings;
    };

    encodeQueryData(data: any) {
        const ret = [];
        for (const d in data) {
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }
        return ret.join('&');
    }
}
