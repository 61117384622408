export const environment = {
    production: true,
    development: true,
    demo: 'default',
    appUrl: 'https://dev.dashboard.pushub.net',
    socketUrl: 'https://dev.dashboard.pushub.net',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4',
    roles: [
        {
            name: 'admin',
            label: 'Admin'
        },
        {
            name: 'DSPManager',
            label: 'DSP Manager'
        },
        {
            name: 'user',
            label: 'User'
        }
    ]
};
