import { Component, EventEmitter, Input, OnInit, OnDestroy, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/core/service/auth.service';
import { EventService } from 'src/app/core/service/event.service';
import { LEFT_SIDEBAR_TYPE_CONDENSED, LEFT_SIDEBAR_TYPE_DEFAULT } from '../models/layout.model';
import { BrandItem } from '../models/brands.model';
import { CreateNewMenuOption } from '../models/create-new.model';
import { Language } from '../models/language.model';
import { MegaMenuItem } from '../models/mega-menu.model';
import { NotificationItem } from '../models/notification.model';
import { ProfileOptionItem } from '../models/profileoption.model';
import { SearchResultItem, SearchUserItem } from '../models/search.model';
import { ApiCallService } from 'src/app/services/api-call.service';
import Swal from 'sweetalert2';
import { SocketService } from 'src/app/core/service/socket.service';
import { ToDoItem } from 'src/app/shared/widget/todo/todo.model';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-topbar',
    templateUrl: './topbar.component.html',
    styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent implements OnInit, OnDestroy {

    @Input() layoutType: string = '';
    @Input() leftSidebarTheme: string = 'light';
    createMenuOptions: CreateNewMenuOption[] = [];
    megaMenuItems: MegaMenuItem[] = [];
    notificationList: NotificationItem[] = [];
    languages: Language[] = [];
    brands: BrandItem[] = [];
    profileOptions: ProfileOptionItem[] = [];
    selectedLanguage?: Language;
    searchResults: SearchResultItem[] = [];
    searchUsers: SearchUserItem[] = [];
    todoList: ToDoItem[] = [];
    env = environment;

    unreadNotification: number = 0;

    loggedInUser: any = {};
    topnavCollapsed: boolean = false;

    // output events
    @Output() mobileMenuButtonClicked = new EventEmitter();
    @Output() settingsButtonClicked = new EventEmitter<boolean>();


    constructor(
        private authService: AuthenticationService,
        private eventService: EventService,
        private socketService: SocketService,
        private apiCall: ApiCallService,

    ) {
    }


    ngOnInit(): void {
        this._fetchMenus();
        this._fetchSearchData();
        this._initSocketEvents();
        this._fetchUnreadNotifications();
        this._fetchBrands();
        this._fetchLanguages();
        this._fetchProfileOptions();

        this.loggedInUser = this.authService.currentUser();

        document.addEventListener('fullscreenchange', this.exitHandler);
        document.addEventListener("webkitfullscreenchange", this.exitHandler);
        document.addEventListener("mozfullscreenchange", this.exitHandler);
    }

    ngOnDestroy(): void {
        this._destroySocketEvents();
    }

    /**
     * fetches menu options
     */
    _fetchMenus(): void {
        this.createMenuOptions = [{
            id: 1,
            label: 'New Projects',
            icon: 'fe-briefcase',
        },
        {
            id: 2,
            label: 'Create Users',
            icon: 'fe-user',
        },
        {
            id: 3,
            label: 'Revenue Report',
            icon: 'fe-bar-chart-line-',
        },
        {
            id: 4,
            label: 'Settings',
            icon: 'fe-settings',
        },
        {
            id: 4,
            label: 'Help & Support',
            icon: 'fe-headphones',
        }];

        this.megaMenuItems = [
            {
                id: 1,
                menuTitle: 'UI Components',
                menuItems: [
                    'Widgets',
                    'Nestable List',
                    'Range Sliders',
                    'Masonry Items',
                    'Sweet Alerts',
                    'Treeview Page',
                    'Tour Page',
                ],
            },
            {
                id: 2,
                menuTitle: 'Applications',
                menuItems: [
                    'eCommerce Pages',
                    'CRM Pages',
                    'Email',
                    'Calendar',
                    'Team Contacts',
                    'Task Board',
                    'Email Templates',
                ],
            },
            {
                id: 3,
                menuTitle: 'Extra Pages',
                menuItems: [
                    'Left Sidebar with User',
                    'Menu Collapsed',
                    'Small Left Sidebar',
                    'New Header Style',
                    'Search Result',
                    'Gallery Pages',
                    'Maintenance & Coming Soon',
                ],
            },
        ];
    }

    deleteAllNotifications(): void {
        this.apiCall
            .delete({ dest: "/api/v1/notification/", skipLoading: true })
            .then((res: any) => {
                this.notificationList = res.body;
                // this.hasLoaded = true;
            })
            .catch((err: any) => { });
    }

    _initSocketEvents() {
        this.socketService.on('unreadNotificationsCount', (data: any) => {
            this.unreadNotification = data.count;
        });
    }

    _destroySocketEvents() {
        this.socketService.off('unreadNotificationsCount');
    }

    _fetchUnreadNotifications() {
        this.socketService.emit('unreadNotifications');
    }

    openNotifications() {
        this._fetchNotifications();
    }

    /**
     * Fetches notifications
     */
    _fetchNotifications(): void {
        this.apiCall
            .get({ dest: "/api/v1/notification/", skipLoading: true })
            .then((res: any) => {
                for (let notification of res.body) {
                    switch (notification.type) {
                        case 'post': {
                            notification.link = 'posts/' + notification.ref;
                            break;
                        }
                        case 'adjusting': {
                            notification.link = 'automations/campaign-adjusting?id=' + notification.ref;
                            break;
                        }
                        case 'ticket': {
                            notification.link = 'apps/tickets/details?id=' + notification.ref;
                            break;
                        }
                        case 'blacklist-checker': {
                            //https://login.pushub.net/admin#grid/RemoteFeeds/?search=
                            // regex to get number of notification.body
                            let remoteFeedId = notification.body.match(/\d+/g);
                            notification.link = 'https://login.pushub.net/admin#grid/RemoteFeeds/?search=' + remoteFeedId;
                            break;
                        }
                        case 'pubsub-whitelister': {
                            notification.link = 'automations/pubsub-whitelister';
                            break;
                        }
                        case 'tag-grader': {
                            notification.link = 'automations/tag-grader';
                            break;
                        }
                        case 'creative-optimizer': {
                            notification.link = 'automations/creative-optimizer';
                            break;
                        }
                    }
                }
                this.unreadNotification = res.body.filter((e: any) => !e.read).length;
                this.notificationList = res.body;
                if (this.notificationList.filter(e => !e.read).length > 0) {
                    this.socketService.emit('readNotification', {
                        notifications: this.notificationList.filter(e => !e.read).map(e => e._id)
                    })
                }
                // this.hasLoaded = true;
            })
            .catch((err: any) => { });
        // this.notificationList = [{
        //   text: 'Caleb Flakelar commented on Admin',
        //   isActive: true,
        //   subText: '1 min ago',
        //   icon: 'mdi mdi-comment-account-outline',
        //   bgColor: 'primary',
        //   redirectTo: '/dashboard-1'
        // },
        // {
        //   text: 'New user registered.',
        //   subText: '5 min ago',
        //   icon: 'mdi mdi-account-plus',
        //   bgColor: 'info',
        //   redirectTo: '/dashboard-1'
        // },
        // {
        //   text: 'Cristina Pride',
        //   subText: 'Hi, How are you? What about our next meeting',
        //   avatar: 'assets/images/users/user-4.jpg',
        //   bgColor: 'success',
        //   redirectTo: '/dashboard-1'
        // },
        // {
        //   text: 'Caleb Flakelar commented on Admin',
        //   subText: '2 days ago',
        //   icon: 'mdi mdi-comment-account-outline',
        //   bgColor: 'danger',
        //   redirectTo: '/dashboard-1'
        // },
        // {
        //   text: 'Caleb Flakelar commented on Admin',
        //   subText: '1 min ago',
        //   icon: 'mdi mdi-comment-account-outline',
        //   bgColor: 'primary',
        //   redirectTo: '/dashboard-1'
        // },
        // {
        //   text: 'New user registered.',
        //   subText: '5 min ago',
        //   icon: 'mdi mdi-account-plus',
        //   bgColor: 'info',
        //   redirectTo: '/dashboard-1'
        // },
        // {
        //   text: 'Cristina Pride',
        //   subText: 'Hi, How are you? What about our next meeting',
        //   avatar: 'assets/images/users/user-1.jpg',
        //   bgColor: 'success',
        //   redirectTo: '/dashboard-1'
        // },
        // {
        //   text: 'Caleb Flakelar commented on Admin',
        //   subText: '2 days ago',
        //   icon: 'mdi mdi-comment-account-outline',
        //   bgColor: 'danger',
        //   redirectTo: '/dashboard-1'
        // }];
    }

    /**
     * Fetches supported languages
     */
    _fetchLanguages(): void {
        this.languages = [{
            id: 1,
            name: 'English',
            flag: 'assets/images/flags/us.jpg',
        },
        {
            id: 2,
            name: 'German',
            flag: 'assets/images/flags/germany.jpg',
        },
        {
            id: 3,
            name: 'Italian',
            flag: 'assets/images/flags/italy.jpg',
        },
        {
            id: 4,
            name: 'Spanish',
            flag: 'assets/images/flags/spain.jpg',
        },
        {
            id: 5,
            name: 'Russian',
            flag: 'assets/images/flags/russia.jpg',
        }];
        this.selectedLanguage = this.languages[0];
    }

    /**
     * Fetches brands
     */
    _fetchBrands(): void {
        this.brands = [{
            id: 1,
            name: 'AdKernel',
            link: 'https://login.pushub.net/admin',
            logo: 'assets/images/brands/adkernel.png',
        },
        {
            id: 2,
            name: 'CryptoVOT',
            link: 'https://cryptovot.com',
            logo: 'assets/images/brands/cryptovot.png',
        },
        {
            id: 2,
            name: 'NewsVOT',
            link: 'https://newsvot.com',
            logo: 'assets/images/brands/newsvot.png',
        },
        ];
    }

    /**
     * Fetches profile options
     */
    _fetchProfileOptions(): void {
        this.profileOptions = [
            // {
            //   label: 'My Account',
            //   icon: 'fe-user',
            //   redirectTo: '/apps/contacts/profile',
            // },
            {
                label: 'Settings',
                icon: 'fe-settings',
                redirectTo: '/settings/' + this.authService.currentUser()?._id,
            },
            // {
            //   label: 'Lock Screen',
            //   icon: 'fe-lock',
            //   redirectTo: '/auth/lock-screen',
            // },
            {
                label: 'Logout',
                icon: 'fe-log-out',
                redirectTo: '/auth/logout',
            }
        ];

    }

    /**
     * Fetches search results
     */
    _fetchSearchData(): void {
        this.searchResults = [{
            id: 1,
            text: 'Analytics Report',
            icon: 'fe-home',
        },
        {
            id: 2,
            text: 'How can I help you?',
            icon: 'fe-aperture',
        },
        {
            id: 3,
            text: 'User profile settings',
            icon: 'fe-settings',
        }];

        this.searchUsers = [{
            id: 1,
            name: 'Erwin Brown',
            position: 'UI Designer',
            profile: 'assets/images/users/user-2.jpg'
        },
        {
            id: 2,
            name: 'Jacob Deo',
            position: 'Developer',
            profile: 'assets/images/users/user-5.jpg'
        }]

    }

    /**
     * changes left sidebar width 
     */
    changeSidebarWidth(): void {
        if (document.body.hasAttribute('data-leftbar-size') && document.body.getAttribute('data-leftbar-size') === "condensed") {
            this.eventService.broadcast('changeLeftSidebarType', LEFT_SIDEBAR_TYPE_DEFAULT);
        }
        else {
            this.eventService.broadcast('changeLeftSidebarType', LEFT_SIDEBAR_TYPE_CONDENSED);
        }


    }

    /**
     * exit handler for full screen mode
     */
    exitHandler(): void {
        let document: any = window.document;

        if (!document.webkitIsFullScreen && !document.mozFullScreen && !document.msFullscreenElement) {
            document.body.classList.remove('fullscreen-enable');
        }
    }

    /**
     * toggles full screen mode
     */
    toggleFullScreen(): void {
        let document: any = window.document;

        document.body.classList.toggle('fullscreen-enable');

        let elem = document.querySelector('.maximize-icon');

        if (elem.hasAttribute('data-toggle') && document.body.getAttribute('data-toggle') === "fullscreen") {
            document.body.removeAttribute('data-toggle');
        }
        else {
            elem.setAttribute('data-toggle', 'fullscreen')
        }

        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen();
            }
        } else {
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }



    /**
    * Toggles the right sidebar
    */
    toggleRightSidebar() {
        this.settingsButtonClicked.emit();
    }

    /**
   * Toggle the menu bar when having mobile screen
   */
    toggleMobileMenu(event: any) {
        this.topnavCollapsed = !this.topnavCollapsed;
        event.preventDefault();
        this.mobileMenuButtonClicked.emit();
    }


}
