// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
    production: false,
    demo: 'default',
    development: true,
    appUrl: 'http://localhost:8080',
    socketUrl: 'http://localhost:8080',
    cdnUrl: 'http://localhost:8080',
    GOOGLE_MAPS_API_KEY: 'AIzaSyDpgQMpcfx1QU-8SM-ljcgjG4xrYtIYby4',
    roles: [
        {
            name: 'admin',
            label: 'Admin'
        },
        {
            name: 'DSPManager',
            label: 'DSP Manager'
        },
        {
            name: 'user',
            label: 'User'
        }
    ]
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.