import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './core/guards/auth.guard';
import { LayoutContainerComponent } from './layout/layout-container.component';
import { PublicLayoutComponent } from './layout/public-layout/public-layout.component';

const routes: Routes = [

    {
        path: '',
        component: LayoutContainerComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule),
            },
            {
                path: 'apps',
                loadChildren: () => import('./apps/apps.module').then(m => m.AppsModule),
            },
            {path: 'tickets', loadChildren: () => import('./pages/tickets/tickets.module').then(m => m.TicketsModule)},
            { path: 'users', loadChildren: () => import('./pages/users/users.module').then(m => m.UsersModule) },
            { path: 'settings', loadChildren: () => import('./pages/settings/settings.module').then(m => m.SettingsModule) },
            { path: 'system/settings', loadChildren: () => import('./pages/system-settings/system-settings.module').then(m => m.SystemSettingsModule) },
            { path: 'customers', loadChildren: () => import('./pages/customers/customers.module').then(m => m.CustomersModule) },
            { path: 'landing-pages', loadChildren: () => import('./pages/landing-pages/landing-pages-routing.module').then(m => m.LandingPagesRoutingModule) },
            { path: 'reports', loadChildren: () => import('./pages/reports/reports.module').then(m => m.ReportsModule) },
            { path: 'traffic', loadChildren: () => import('./pages/traffic-estimator/traffic-estimator.module').then(m => m.TrafficEstimatorModule) },
        ]
    },
    {
        path: 'auth',
        component: PublicLayoutComponent,
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled' })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
